$color-home-background: #F2F5F9;
$color-green: #6d9c7d;
$color-deep-blue: #293C5F;
$color-white: #fff;
$color-light-blue: #47B2E4;
$color-theme: #37507E;

@mixin respond($breakpoint) {
    @if $breakpoint == phone {
      @media (max-width: 37.5em) {
        @content;
      } //600px
    }
    @if $breakpoint == tab-port {
      @media (max-width: 56.25em) {
        @content;
      } //900px
    }
    @if $breakpoint == tab-land {
      @media (max-width: 75em) {
        @content;
      } //1200px
    }
    @if $breakpoint == big-desktop {
      @media (min-width: 112.5em) {
        @content;
      } //1800px
    }
  }

html {
    font-size: 62.5%;
    height: 100%;
    box-sizing: border-box;
  
    @include respond(tab-port) {
      font-size: 50%; // 1rem = 8px, 8/16 * 100 = 50%
    }
  
    @include respond(phone) {
      font-size: 45%; // 1rem = 8px, 8/16 * 100 = 50%
    }
}

body{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    height: 100%;
    background-color: $color-home-background
  }

  @keyframes moveInLeft {
    0% {
      opacity: 0;
      right: -30rem
    }
  
    100% {
      opacity: 1;
      right: 0; /*to look the way it should look*/
    }
  }

  @keyframes upAndDown {
    0% {
        margin-top: 2rem
    }

    100% {
      margin-top: -2rem; /*to look the way it should look*/
    }
  }

  @-webkit-keyframes up-down {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-10px);
    }
  }
  
  @keyframes up-down {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-10px);
    }
  }

  @keyframes scaleUp {
    0% {
        transform: scale(.5)
    }

    100% {
      transform: scale(1)
    }
  }
  
input,
textarea,
button,
select,
a,
label,
span,
div,
img,
p{
    -webkit-tap-highlight-color: rgba(0,0,0,0)
}

.overall-container{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.main-container{
    width: 100%;
    height: 100%;
    position: relative;
    //overflow: hidden;
}
.main-container-tweak{
    overflow: hidden;
}

.navbar-container{
    width: 100%;
    height: 7.2rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: all 0.15s;
    //background: $color-theme;
}

// .navbar-container-bckgrnd1{
//     background: $color-theme;
//  }
 
.navbar-container-bckgrnd2{
    background: rgba(40, 58, 90, 0.9);
}

.navigation{
    &__checkbox {
        display: none;
    }
    
    &__button {
        display: none;
        height: 5rem;
        width: 5rem;
        position: fixed;
        top: .25rem;
        right: 2rem;
        z-index: 8;
        text-align: center;
        cursor: pointer;

        @media(max-width: 700px) {
            display: block
        }
        
        @media(max-width: 350px) {
            right: 1.3rem;
        }
        @media(max-width: 300px) {
            right: 1rem;
        }
    }

    &__checkbox:checked ~ .navbar-container {
        //height: 26rem;
        background: $color-home-background;
    } 

    &__checkbox:checked ~ &__side-bar-wrapper {
        display: block;

        // .side-bar-container{
        //     //right: 0;
        //     animation-name: moveInLeft;
        //     animation-duration: .5s;
        //     animation-timing-function: ease-out;
        // }
    } 

    &__checkbox:checked ~ .side-bar-container{
        display: flex;
        animation-name: moveInLeft;
        animation-duration: .5s;
        animation-timing-function: ease-out;
    }

    &__checkbox:checked ~ &__background .option-list{
        opacity: 1;
    } 

    &__checkbox:checked ~ &__nav {
        width: 100%;
        opacity: 1;
    } 

    &__checkbox:checked ~ &__button {
        z-index: 8;
        right: 1rem;

        .navigation__icon{
            margin-top: 5.2rem;
            &,
            &::before,
            &::after {
                background-color: $color-home-background;  // $color-theme
                width: 2.5rem
            }
        }
    }

     // ICON
     &__icon {
         position: relative;
         margin-top: 3.6rem;

         &,
         &::before,
         &::after {
             width: 3.5rem;  //3rem
             height: 2px;
             background-color: $color-white;
             display: inline-block;
         }

         &::before,
         &::after {
             content: "";
             position: absolute;
             left: 0;
             transition: all .2s;
         }

         &::before {
             top: -.8rem;
             //transform-origin: right; // it depects which side we want the transformation to
         }

         &::after {
             top: .8rem;
         }
    }

    &__button:hover &__icon::before {
        top: -1rem;
    }

    &__button:hover &__icon::after {
        top: 1rem;
    }

    &__checkbox:checked + &__button &__icon {
        background-color: transparent; // background color of the original span
    }

    &__checkbox:checked + &__button &__icon::before {
        top: 0;
        transform: rotate(135deg);
    }

    &__checkbox:checked + &__button &__icon::after {
        top: 0;
        transform: rotate(-135deg);
    }
}

.logo-style{
    width: 13rem;
    // height: 25rem;
    position: fixed;
    top: 2rem;
    //transform: translateY(-50%);
    left: 20rem;
    z-index: 6;

    @media(max-width: 1638px){
        left: 7rem
    }
    @media(max-width: 1280px){
        left: 5rem
    }
    @media(max-width: 1070px){
        left: 5rem;
        //width: 15rem;
    }
}

.nav-item-container{
    //background-color: green;
    width: 50rem;
    height: 6rem;
    margin-right: 38rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    @media(max-width: 1638px){
        margin-right: 23rem
    }
    @media(max-width: 1280px){
        margin-right: 15rem
    }
    @media(max-width: 1070px){
        margin-right: 10rem
    }
    @media(max-width: 760px){
        margin-right: 5rem
    }
    @media(max-width: 700px){
        display: none
    }
}
.nav-item{
    text-decoration: none;
    font-size: 1.6rem;
    color: #f5f5f5;
    cursor: pointer;
    outline: none;

    &:visited{
        color: #f5f5f5;
        font-family: 'Open Sans', sans-serif;
        font-weight: 500;
    }
    &:hover{
        color: $color-light-blue
    }
}

.active-link{
    color: $color-light-blue;
    &:visited{
        color: $color-light-blue;
    }
}

.navigation__side-bar-wrapper{
    display: none;
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0, .6);
    z-index: 6;
}
.side-bar-container{
    display: none;
    flex-direction: column;
    align-items: center;
    //justify-content: center;  //
    padding: 32% 2rem 5rem 2rem;  // 15rem 2rem 5rem 2rem
    width: 60%;  // 25rem
    height: 100vh;
    position: fixed;
    top: 50%;
    right: 0;
    background-color: #0E1521;  //$color-home-background
    transform: translateY(-50%);
    transition: all 2s ease-in-out;
    box-sizing: border-box;
    z-index: 7;
    box-shadow: 0 0 2.4rem 0 rgba(0, 0, 0, .8);
}

.side-bar-items{
    text-decoration: none;
    font-size: 2.5rem;
    color: $color-home-background; // $color-theme
    cursor: pointer;
    outline: none;
    padding: 1rem 1rem;
    width: 100%;
    margin: 1rem 2rem 0 2rem;
    text-align: center;
    transition: all .3s;
    outline: none;
    text-decoration: none;
    
    &:visited{
        color: $color-home-background;  // $color-theme
        font-family: 'Open Sans', sans-serif;
        font-weight: 500;
    }
    &:hover{
        color: $color-home-background;
        background-color: #233759;  //$color-theme
        border-radius: 1rem;
    }
}

.side-bar-img{
    position: absolute;
    left: 0;
    bottom: -8rem;
    width: 100%;
    height: 45%;
    
}

.interface-container{
    background-color: $color-theme;
    width: 100%;
    height: 80vh;
    padding: 0;
    margin: 0 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;

    @media(max-width: 980px){
        flex-direction: column-reverse;
        height: 100vh;
    }
}
.toggle-nav-bkg{
    position: absolute;
    top: 10rem;
    left: 4rem;
    width: 5rem;
    opacity: 0;
}
.interface-text-container{
    width: 70rem;
    margin-left: 6rem;
    animation-name: moveInBottom;
    animation-duration: 1s;
    animation-timing-function: ease-out;

    @media(max-width: 1470px){
        margin-left: 13rem;
        width: 75rem;
    }

    @media(max-width: 1363px){
        margin-left: 6rem;
    }
    @media(max-width: 1063px){
        margin-left: 5rem;
        width: 80rem
    }
    @media(max-width: 1038px){
        margin-left: 10rem;
        width: 70rem
    }
    @media(max-width: 980px){
        width: 70rem;
        margin-left: 3rem;
        margin-right: 3rem;
        margin-top: 3rem;
    }
    @media(max-width: 525px){
        width: 80%;
        margin-left: 10%;
        margin-right: 10%
    }
   
}
.interface-image-container{
    margin-left: 10rem;
    margin-right: 6rem;
    animation-name: scaleUp;
    animation-duration: 1s;
    animation-timing-function: ease-out;

    @media(max-width: 1470px){
        margin-right: 13rem;
    }
    @media(max-width: 1363px){
        margin-right: 6rem;
    }
    @media(max-width: 980px){
        margin-left: 2rem;
        margin-right: 2rem;
    }
}
.intro-text_1{
    //font-family: 'Montserrat';
    font-size: 4.8rem;
    font-weight: 700;
    line-height: 5.6rem;
    margin-bottom: 1rem;
    color: $color-white;
    margin: 0 0 1rem 0;

    @media(max-width: 980px){
        text-align: center;
    }
    @media(max-width: 770px){
        font-size: 3.2rem;
        font-weight: 900;
    }
}
.intro-text_2{
    //font-family: 'Montserrat';
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 2.88rem;
    margin: 0 0 .5rem 0;
    //color: #C5C8C3;
    color: rgba(255, 255, 255, 0.6);

    @media(max-width: 980px){
        text-align: center;
    }
    @media(max-width: 770px){
        font-size: 2rem;
        font-weight: 700;
    }
    
}
.illustrator1{
    width: 65rem;
    height: 53rem;
    animation: up-down 2s ease-in-out infinite alternate-reverse both;

    @media(max-width: 1470px){
        width: 53rem;
        height: 42rem;
    }
    @media(max-width: 1200px){
        width: 48rem;
        height: 37rem;
    }
    @media(max-width: 980px){
        width: 38rem;
        height: 30rem;
        //animation-name: none;
    }
    @media(max-width: 600px){
        width: 42rem;
        height: 32rem;
    }
    @media(max-width: 412px){
        width: 36rem;
        height: 27rem;
    }

}
.getStarted-container{
    display: flex;
    flex-direction: row;
    align-items: center;
   // justify-content: space-between;
    margin-top: 7rem;
    width: 40rem;

    @media(max-width: 980px){
        width: 70rem;
        justify-content: center;
    }
    @media(max-width: 525px){
        width: 100%;
    }
}
.getStarted-button{
    padding: 1.5rem 3.2rem;
    background-color: $color-light-blue;
    border-radius: 10rem;
    outline: none;
    text-decoration: none;
    color: $color-white;
    font-size: 1.6rem;
    font-weight: 700;
    margin-right: 2rem;
    cursor: pointer;
    transition: all 0.2s;

    &:hover{
        background-color: #0392D0
    }
}
.play-icon{
    margin-right: 1.7rem;
    font-size: 3.7rem;
    color: $color-home-background;
    cursor: pointer;
    transition: all 0.2s;

    &:hover{
        color: $color-light-blue
    }
}
.play-text{
    color: $color-white;
    font-size: 1.6rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    cursor: pointer;
}
.technology-icons{
    width: 100%;
    height: 9rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 4rem;
    color:rgba(0, 0, 0, .3);
    //overflow: hidden;
}
.each-tech-icon{
    margin: 0 5%;
}