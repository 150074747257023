$color-home-background: #F2F5F9;
$color-green: #6d9c7d;
$color-deep-blue: #293C5F;
$color-white: #fff;
$color-light-blue: #47B2E4;
$color-theme: #37507E;


.team-container{
    width: 100%;
    padding: 7rem 0 5rem 0;
    background-color: $color-home-background;
    overflow: hidden;
}
.team-member-wrapper{
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 6rem auto 0 auto;
    flex-wrap: wrap;
    transition: all ease-in-out 0.4s;

    @media(max-width: 840px){
        width: 95%;
    }
}
.team-member{
    position: relative;
    width: 41rem;
    height: 47rem;
    margin:  0rem 1.5rem 5rem 1.5rem;
    overflow: hidden;
   // background-color: green;

   &:hover {
        .team-member-overlay{
            height: 27rem;
            clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 26%);
            background-color: rgba(#D7B15B, .7);
        }
        .team-member-details{
            clip-path: none;
            border-top: 5px solid rgba(#D7B15B, .7);

            .member-name{
                margin: 1.7rem 0 0 0
            }
        }
        .member-info-wrapper{
            display: flex;
        }
    }
}
.team-member-details{
    background-color: $color-theme;
    height: 11rem;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    clip-path: polygon(50% 0, 100% 37%, 100% 100%, 0 100%, 0 37% );
    z-index: 2;
    transition: all .4s
}
.team-member-overlay{
    width: 32rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 22rem;
    background-color: rgba(0,0,0, .04);
    transition: all .4s
}
.team-member-image{
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
// .team-member-img{
//     transform: rotateY(360deg);
// }
.member-name{
    font-size: 2rem;
    color: $color-white;
    text-align: center;
    margin: 4rem 0 0 0;
    padding: 0 1rem;
    transition: all .4s
}
.member-role{
    font-size: 1.5rem;
    color: rgba($color-home-background, .6);
    margin: 0;
    padding: 1rem;
    text-align: center;
}
.member-info-wrapper{
    position: absolute;
    display: none;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3rem;
    border-top: 1px solid rgba($color-home-background, .6);
    transition: all .4s
}
.member-info-1{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55%;
    border-right: 1px solid rgba($color-home-background, .6);
}
.member-info-2{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    border-left: 1px solid rgba($color-home-background, .6);
}
.member-experience{
    text-transform: uppercase;
    font-size: 1.1rem;
    color: rgba($color-home-background, .7);
}
.age-icon{
    font-size: 1.5rem;
    position: absolute;
    left: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    color: rgba($color-home-background, .7);
}
// .hide-member{
//     display: none;

//     @media(max-width: 1550px){
//        display: block;
//     }
// }