$color-home-background: #F2F5F9;
$color-green: #6d9c7d;
$color-deep-blue: #293C5F;
$color-white: #fff;
$color-light-blue: #47B2E4;
$color-theme: #37507E;

// @mixin respond($breakpoint) {
//     @if $breakpoint == phone {
//       @media (max-width: 37.5em) {
//         @content;
//       } //600px
//     }
//     @if $breakpoint == tab-port {
//       @media (max-width: 56.25em) {
//         @content;
//       } //900px
//     }
//     @if $breakpoint == tab-land {
//       @media (max-width: 75em) {
//         @content;
//       } //1200px
//     }
//     @if $breakpoint == big-desktop {
//       @media (min-width: 112.5em) {
//         @content;
//       } //1800px
//     }
// }

@keyframes moveInBottom {
    0% {
      opacity: 0;
      transform: translateY(0rem); 
    }
  
    7% {
        opacity: 0;
      transform: translateY(12rem);
    }
  
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

.about-container{
    margin: 0rem 0 3rem 0;
    background-color: $color-white;
    padding: 7rem 0rem 5rem 0;
    width: 100%;
    box-sizing: border-box;
    //overflow: hidden;
}
.about-title{
    color: #37517E;
    font-weight: 800;
    font-size: 3.2rem;
    line-height: 3.8rem;
    text-transform: uppercase;
    //font-family: 'Montserrat';
    text-align: center;
    margin: 0;
    padding: 0 3rem;
}
.about-content-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding: 2rem 0rem;
    margin: 3rem 0 0 0;

    @media(max-width: 980px) {
        flex-direction: column;
        align-items: center;
    }
}
.about-text-block_1{
    width: 63rem;
    margin-right: 3rem;

    @media(max-width: 1380px) {
        width: 52rem
    }
    @media(max-width: 1145px) {
        width: 46rem;
        margin-right: 1.5rem;
    }
    @media(max-width: 980px) {
        margin: 0 3rem;
        width: 80%
    }
    @media(max-width: 500px) {
        width: 85%
    }
}
.about-text-block_2{
    width: 63rem;
    margin-left: 3rem;

    @media(max-width: 1380px) {
        width: 52rem
    }
    @media(max-width: 1145px) {
        width: 46rem;
        margin-left: 1.5rem;
    }
    @media(max-width: 980px) {
        margin: 3rem 3rem 0 3rem;
        width: 80%
    }
    @media(max-width: 500px) {
        width: 85%
    }
}
.about-text{
    padding: 0;
    margin: 0 0 2rem 0;
}
.move-from-bottom{
    animation-name: moveInBottom;
    animation-duration: 1s;
    animation-timing-function: ease-out;
}
.about-text-list{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 1rem;
}
.bullet-check{
    font-size: 2rem;
    color: $color-light-blue;
}
.list-text{
    margin: 0;
    padding: 0 0 0 1rem
}


