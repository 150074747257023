$color-home-background: #F2F5F9;
$color-green: #6d9c7d;
$color-deep-blue: #293C5F;
$color-white: #fff;
$color-light-blue: #47B2E4;
$color-theme: #37507E;


.projects-container{
    width: 100%;
    padding: 7rem 0 5rem 0;
    background-color: $color-white;
    overflow: hidden;
}
.projects-wrapper{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 3rem auto 0 auto;
    flex-wrap: wrap;
    transition: all ease-in-out 0.4s;

    @media(max-width: 1640px){
       width: 90%
    }
}
.each-projects-wrapper{
    position: relative;
    width: 42rem;
    height: 23rem;
    margin: 0 1%;
    box-sizing: border-box;
    margin-bottom: 2rem;
    background-color: $color-home-background;
    transition: all ease-in-out 0.4s;
    overflow: hidden;

    &:hover{
        .project-image{
            transform: scale(1.14);
        }
        .project-info{
            opacity: 1;
        }

    }

    @media(max-width: 1440px){
        width: 34rem;
        height: 20rem;
    }
    @media(max-width: 1200px){
        width: 30rem;
        height: 18rem;
    }
    @media(max-width: 1065px){
        width: 35rem;
        height: 18rem;
    }
    @media(max-width: 950px){
        width: 40rem;
        height: 21rem;
    }
    @media(max-width: 780px){
        width: 70rem;
        height: 30rem;
    }
}
.project-image{
    width: 100%;
    height: 100%;
    transition: all ease-in-out 0.5s;
}
.project-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0;
    position: absolute;
    left: .5rem;
    bottom: 0;
    z-index: 3;
    right: .5rem;
    transition: all 0.3s;
    background: rgba(55, 81, 126, 0.8);
    height: 6.5rem;
    padding: 0rem 1.3rem;
}
.project-info-title{
    display: inline-block;
    font-size: 1.8rem;
    color: $color-light-blue;
    font-weight: 600;
    margin: 0;
    padding: 0;
    width: 14rem;
    cursor: pointer;
    transition: all .2s;

    &:hover{
        color: rgba($color-light-blue, .7);
    }
}
.project-info-text{
    font-size: 1.5rem;
    color: $color-white;
    padding: 0;
    margin: 0;
}
.link-icon{
    font-size: 2.7rem;
    position: absolute;
    right: 1.3rem;
    top: 50%;
    color: $color-white;
    transform: translateY(-50%);
    transition: all .2s;

    &:hover{
        color: $color-light-blue
    }
}
.plus-icon{
    right: 4.2rem;
}
