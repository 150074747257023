$color-home-background: #F2F5F9;
$color-green: #6d9c7d;
$color-deep-blue: #293C5F;
$color-white: #fff;
$color-light-blue: #47B2E4;
$color-theme: #37507E;

@mixin respond($breakpoint) {
    @if $breakpoint == phone {
      @media (max-width: 37.5em) {
        @content;
      } //600px
    }
    @if $breakpoint == tab-port {
      @media (max-width: 56.25em) {
        @content;
      } //900px
    }
    @if $breakpoint == tab-land {
      @media (max-width: 75em) {
        @content;
      } //1200px
    }
    @if $breakpoint == big-desktop {
      @media (min-width: 112.5em) {
        @content;
      } //1800px
    }
}

input,
textarea,
button,
select,
a,
label,
span,
div,
p{
    -webkit-tap-highlight-color: rgba(0,0,0,0)
}

.reasons-container{
    margin: 4rem 0 3rem 0;
    padding: 6rem 0rem 5rem 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media(max-width: 990px){
        flex-direction: column-reverse;
    }
}
.reasons-texts{
    width: 44%;

    @media(max-width: 1200px){
        width: 46%
    }
    @media(max-width: 990px){
        width: 70%;
    }
    @media(max-width: 500px){
        width: 85%;
    }

}
.reason-title{
    font-family: 'Montserrat';
    font-size: 3.4rem;
    line-height: 4rem;
    color: $color-theme;
    margin: 0;
    font-weight: 500;
}
.reasons-illustrator{
    width: 40%;
    margin: 0 0 0 3%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width: 1200px){
        width: 47%
    }
    @media(max-width: 990px){
        width: 60%;
        margin-bottom: 3rem;
    }
    @media(max-width: 480px){
        width: 80%;
    }
}
.illustrator-icon{
    display: block;
    width: 60rem;
    height: 50rem;
    //margin: 0 auto;
    @media(max-width: 1500px){
        transform: scale(.8);
     }
     @media(max-width: 990px){
        transform: scale(1);
        height: 30rem;
        width: 40rem;
     }
}
.style-us{
    font-weight: bold;
}
.reason-description{
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5rem;
    line-height: 2.25rem;
    color: #848484;
    font-weight: 500;
}
.each-reason-container{
    position: relative;
    background-color: $color-white;
    padding: 1.6rem 2rem;
    margin-bottom: 1.5rem;
    transition: all 2s;
    // -webkit-transition: all 2s;
    // -moz-transition: all 2s; 
    // -ms-transition: all 2s; 
    // -o-transition: all 2s;
}
.each-reason-header-text{
    font-size: 1.7rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0, .7);
    transition: all 2s;
    // -webkit-transition: all 2s;
    // -moz-transition: all 2s;
    // -ms-transition: all 2s;
    // -o-transition: all 2s; 

    &:hover{
        color: $color-light-blue;
    }
}
.reason-number{
    color: $color-light-blue;
    font-size: 1.9rem;
    font-weight: 700;
}

.display-reason{
    display: block;
    font-size: 1.5rem;
    margin: 1.8rem 0 0 0;
    transition: all 2s;
}
.each-reason-text{
    display: none;
    font-size: 1.5rem;
    margin: 1.8rem 0 0 0;
    transition: all 2s;
}
.toggle-reason-text{
    font-size: 1.8rem;
    position: absolute;
    right: 3rem;
    top: 2.3rem;
    color: rgba(0,0,0, .7);
}
.scale-from-down{
    animation-name: scaleUp;
    animation-duration: 1s;
    animation-timing-function: ease-out;
}
